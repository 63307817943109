<template>
  <div>
    <h1 v-if="props.error.statusCode === 404">
      {{ $t('p_ErrorPage.Page not found') }}
    </h1>
    <h1 v-else>{{ $t('p_ErrorPage.An error has occurred') }}</h1>
    <NuxtLink to="/">{{ $t('p_ErrorPage.Back to homepage') }}</NuxtLink>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  error: {
    statusCode: number
  }
}

const props = defineProps<Props>()
</script>
